// NavLink.js
function NavLink({ href, className, children }) {
    return (
        <li className={className}>
            <a href={href} target="_blank" rel="noopener noreferrer">{children}</a>
        </li>
    );
}

export default NavLink;
