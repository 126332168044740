// Images.js


// jelly imports
import jellysImg1 from '../images/jellys/1.png';
import jellysImg2 from '../images/jellys/2.png';
import jellysImg3 from '../images/jellys/3.png';
import jellysImg4 from '../images/jellys/4.png';
import jellysImg5 from '../images/jellys/5.png';
import jellysImg6 from '../images/jellys/6.png';
import jellysImg7 from '../images/jellys/7.png';
import jellysImg8 from '../images/jellys/8.png';
import jellysImg9 from '../images/jellys/9.png';
import jellysImg10 from '../images/jellys/10.png';
import jellysImg11 from '../images/jellys/11.png';
import jellysImg12 from '../images/jellys/12.png';
import jellysImg13 from '../images/jellys/13.png';
import jellysImg14 from '../images/jellys/14.png';
import jellysImg15 from '../images/jellys/15.png';
import jellysImg16 from '../images/jellys/16.png';
import jellysImg17 from '../images/jellys/17.png';
import jellysImg18 from '../images/jellys/18.png';
import jellysImg19 from '../images/jellys/19.png';
import jellysImg20 from '../images/jellys/20.png';
import jellysImg21 from '../images/jellys/21.png';
import jellysImg22 from '../images/jellys/22.png';
import jellysImg23 from '../images/jellys/23.png';
import jellysImg24 from '../images/jellys/24.png';
import jellysImg25 from '../images/jellys/25.png';
import jellysImg26 from '../images/jellys/26.png';
import jellysImg27 from '../images/jellys/27.png';
import jellysImg28 from '../images/jellys/28.png';
import jellysImg29 from '../images/jellys/29.png';
import jellysImg30 from '../images/jellys/30.png';

// qbitz import
import qbitzImg1 from '../images/qbitz/1.png';

const jellys = {
  jellysImg1, jellysImg2, jellysImg3, jellysImg4, jellysImg5,
  jellysImg6, jellysImg7, jellysImg8, jellysImg9, jellysImg10,
  jellysImg11, jellysImg12, jellysImg13, jellysImg14, jellysImg15,
  jellysImg16, jellysImg17, jellysImg18, jellysImg19, jellysImg20,
  jellysImg21, jellysImg22, jellysImg23, jellysImg24, jellysImg25,
  jellysImg26, jellysImg27, jellysImg28, jellysImg29, jellysImg30,
}

const qbitz = {
  qbitzImg1
}

const Images = {
  jellys,
  qbitz
}
console.log(Images); // Log the Images object

export default Images;