import React, { useEffect, useState } from 'react';

const FlavorGallery = () => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await fetch(`${process.env.PUBLIC_URL}/images/flavors/flavors.json`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setImages(data);
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    fetchImages();
  }, []);

  return (
    <div className="bg-slate-300 min-h-screen rounded-xl">
      <div className="container mx-auto py-8">
        <h1 className="text-3xl font-bold mb-8 text-center text-slate-800">50 Delicious Flavors</h1>
        <div className="bg-black grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 rounded-lg">
          {images.map((imageName, index) => (
            <div key={index} className="relative">
              <img
                src={`${process.env.PUBLIC_URL}/images/flavors/${imageName}`}
                alt={imageName}
                className="w-full h-auto"
              />
              <div className="absolute bottom-0 left-0 right-0 bg-gray-800 bg-opacity-75 text-blue-300 text-center py-1 rounded-lg">
                {imageName.replace(/\.[^/.]+$/, '')} {/* Display filename without extension */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FlavorGallery;
