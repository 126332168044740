import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Section from './Section';
import Modal from './Modal'; // Import the Modal component

const SectionOne = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleClick = () => {
        setIsModalOpen(!isModalOpen);
    };

    return (
        <Section
            title="Dank Jelly Bean's"
            imgSrc="/images/jellys/sectionOne.png"
            content={
                <>
                    <p>Dank Jelly Bean's is a collection of 3000 NFT's made in 50 delicious flavors. Each original NFT is a gateway to future drops and the only way to get the Annual Dank Jelly Bean's Graphic Novel. Collect all 50 and become eligible for one of the Dank Jelly Bean's XP's. A collection of *25 unique NFT's exclusive to this elite group of Confectionaires...</p>
                    <br />
                    <p>*13 JLBXPs on PulseChain and 12 on DJLBXPs on Base Network</p>
                    <div className="flex justify-end space-x-4 mt-4">
                        <Link to="/Explore" className="bg-blue-500 text-white font-bold py-2 px-4 rounded-lg hover:bg-blue-700 flex items-center">
                            <img src="/images/base.png" alt="Base" className="w-4 h-4 mr-2" />
                            Base
                        </Link>
                        <Link to="/ExplorePulse" className="bg-blue-500 text-white font-bold py-2 px-4 rounded-lg hover:bg-blue-700 flex items-center">
                            <img src="/images/pulsechain.png" alt="PulseChain" className="w-4 h-4 mr-2" />
                            PulseChain
                        </Link>
                        <button 
                            onClick={handleClick} // Update the button to toggle the modal
                            className="bg-green-500 text-white font-bold py-2 px-4 rounded-lg hover:bg-green-700"
                        >
                            Mint
                        </button>
                    </div>
                    <Modal isOpen={isModalOpen} onClose={handleClick} /> {/* Render the Modal component */}
                </>
            }
        />
    );
};

export default SectionOne;
