import React from 'react';
import { Link } from 'react-router-dom';
import Section from './Section';

const SectionTwo = () => {
    return (
        <Section
            title="Collect All 50 Delicious Flavors"
            imgSrc="/images/jellys/sectionTwo.png"
            content={
                <>
                    <p>Collecting all 50 flavors makes you eligible for one of 25 Unique Dank Jelly Bean's XP NFTs. Not an easy task, we agree... But the rare ones that do can use the DApp to mint one of 13 XP's on PulseChain and one of 12 on the Base Network.</p>
                    <br />
                    <p>Start Collecting Now!</p>
                    <div className="flex justify-start space-x-4 mt-4">
                        <a href="https://beatbox.market/contract/0x1db88ddAf7e617de5a030F97a39198693e604504" className="bg-blue-500 text-white font-bold py-2 px-4 rounded-lg hover:bg-blue-700 flex items-center" target="_blank" rel="noopener noreferrer">
                            <img src="/images/pulsechain.png" alt="Beatbox Market" className="w-4 h-4 mr-2" />
                            Collect
                        </a>
                        <a href="https://magiceden.io/collections/base/0x624172cad7e9032aff6820b9301a5d92200e7658" className="bg-blue-500 text-white font-bold py-2 px-4 rounded-lg hover:bg-blue-700 flex items-center" target="_blank" rel="noopener noreferrer">
                            <img src="/images/base.png" alt="Magic Eden" className="w-4 h-4 mr-2" />
                            Collect
                        </a>
                        <Link to="/app" className="bg-green-500 text-white font-bold py-2 px-4 rounded-lg hover:bg-green-700">
                            DApp
                        </Link>
                    </div>
                </>
            }
        />
    );
};

export default SectionTwo;
