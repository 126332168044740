// ExploreSidebar.js

import React from 'react';

const ExploreSidebar = ({ sidebarOpen, searchFilters, handleCheckboxChange, attributes }) => {
  return (
    <div className={`explore-sidebar rounded-lg bg-black shadow-xl ${sidebarOpen ? 'block' : 'hidden'}`}>
      <div className="p-4">
        <h2 className="text-slate-300 text-2xl font-bold mb-4">Search Filters</h2>
        {attributes.map((attr, index) => (
          <div key={index} className="mb-4">
            <label className="block text-slate-300">{attr}</label>
            <div>
              {searchFilters[attr].map((option, idx) => (
                <div key={idx} className="text-slate-300 flex items-center mb-2">
                  <input
                    type="checkbox"
                    name={attr}
                    value={option.label}
                    checked={option.checked}
                    onChange={() => handleCheckboxChange(attr, idx)}
                    className="mr-2"
                  />
                  <span>{option.label}</span>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ExploreSidebar;
