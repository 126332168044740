import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const Nav = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <div className="lg:hidden"> {/* Show only on small screens */}
                <button onClick={toggleMenu} className="text-white focus:outline-none">
                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        {isOpen ? (
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        ) : (
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                        )}
                    </svg>
                </button>
            </div>
            <nav className={`lg:flex px-6 lg:items-center lg:space-x-4 ${isOpen ? 'block' : 'hidden'}`}>
                <Link to="/" className="text-white text-lg font-medium hover:text-gray-300">Home</Link> {/* Using Link for internal navigation */}
                <Link to="/Whitepaper" className="text-white text-lg font-medium hover:text-gray-300">Whitepaper</Link>
                <Link to="/flavors" className="text-white text-lg font-medium hover:text-gray-300">Flavors</Link> {/* Using Link for internal navigation */}
                {/* Uncomment these if they are needed later */}
                {/* <a href="https://app.qbitz.io/" className="text-white text-lg font-medium hover:text-gray-300">App</a> */}
                {/* <a href="https://docs.qbitz.io/" className="text-white text-lg font-medium hover:text-gray-300">Docs</a> */}
                <a href="https://dankjellybeans.com/" className="text-white text-lg font-medium hover:text-gray-300">Jelly Bean&apos;s</a>
                <a href="https://mangatang.studio/" className="text-white text-lg font-medium hover:text-gray-300">MangaTang</a>
            </nav>
        </>
    );
};

export default Nav;
