//CardFactory
import underShadow from '../images/shadow.png';

function CardFactory({ title, imageSrc }) {
  return (
    <div className="card">
      {/*<div className="card-header">{title}</div> */}
      <img src={imageSrc} alt={title} className="card-image" />
      <div className="card-footer">
        <img className="under-shadows" src={underShadow} alt="underShadow" />
      </div>
    </div>
  );
}

export default CardFactory;