// App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css'; // Ensure App.css is imported for global styles
import Header from './components/Header'; // Adjust path as per your structure
import HomePageGallery from './components/HomePageGallery'; // Adjust path as per your structure
import SectionOne from './components/SectionOne';
import SectionTwo from './components/SectionTwo'; // Adjust path as per your structure
import SectionThree from './components/SectionThree';
import Footer from './components/Footer'; // Adjust path as per your structure
import Whitepaper from './pages/Whitepaper'; // Adjust path as per your structure
import FlavorGallery from './components/FlavorGallery';
import Explore from './components/Explore';
import ExplorePulse from './components/ExplorePulse';


function App() {
  return (
    <Router>
      <div className="">
        <Header />
        <main className="p-4">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/whitepaper" element={<Whitepaper />} />
            <Route path="/Flavors" element={<FlavorGallery />} />
            <Route path="/Explore" element={<Explore />} />
            <Route path="/ExplorePulse" element={<ExplorePulse />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

const HomePage = () => (
  <>
    <div className="w-screen h-20"></div>
    <HomePageGallery />
    <SectionOne />
    <SectionTwo />
    <SectionThree />
  </>
);

export default App;
