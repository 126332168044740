// Social.js
import NavLink from './NavLink';

function Social() {
    return (
        <div className="social">
            <ul>
                <NavLink href="https://warpcast.com/qbitz111/" className="warpcast">Target</NavLink>
                <NavLink href="https://t.me/csmangatang/" className="telegram">Target</NavLink>
                <NavLink href="https://www.pinterest.com/solight111/" className="pinterest">Target</NavLink>
                <NavLink href="https://twitter.com/tangysuper/" className="twitter">Target</NavLink>
            </ul>
        </div>
    );
}

export default Social;
