import React, { useEffect, useState, useMemo } from 'react';
import ExploreSidebar from './ExploreSidebar';

const Explore = () => {
  const [metafile, setMetafile] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(true); // Set sidebarOpen to true by default
  const [searchFilters, setSearchFilters] = useState({
    Background: [],
    Extra: [],
    Flavor: [],
    Eyes: [],
    Mouth: [],
    Clothes: [],
    Headwear: [],
    Foreground: []
  });

  const attributes = useMemo(() => ['Background', 'Extra', 'Flavor', 'Eyes', 'Mouth', 'Clothes', 'Headwear', 'Foreground'], []);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await fetch(`${process.env.PUBLIC_URL}/images/webp/metadata.json`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setMetafile(data);

        const initialFilters = attributes.reduce((acc, attr) => {
          acc[attr] = [...new Set(data.map(item => item[`attributes[${attr}]`]))].map(option => ({
            label: option,
            checked: false
          }));
          return acc;
        }, {});
        setSearchFilters(initialFilters);
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    fetchImages();
  }, [attributes]);

  const handleCheckboxChange = (attr, index) => {
    const updatedFilters = { ...searchFilters };
    updatedFilters[attr][index].checked = !updatedFilters[attr][index].checked;
    setSearchFilters(updatedFilters);
  };

  const filteredImages = metafile.filter(item => {
    return attributes.every(attr => {
      const selectedOptions = searchFilters[attr].filter(option => option.checked).map(option => option.label);
      return selectedOptions.length === 0 || selectedOptions.includes(item[`attributes[${attr}]`]);
    });
  });

  return (
    <div className="bg-black min-h-screen flex rounded-xl">
      <ExploreSidebar
        sidebarOpen={sidebarOpen}
        searchFilters={searchFilters}
        handleCheckboxChange={handleCheckboxChange}
        attributes={attributes}
      />
      <div className="flex-1 ml-auto w-full">
        <button onClick={() => setSidebarOpen(!sidebarOpen)} className="m-4 p-4 mt-4 bg-slate-800 text-white rounded">
          {sidebarOpen ? 'Close' : 'Open'} Search
        </button>
        <h1 className="text-3xl font-bold mb-8 text-center bg-black text-slate-200">Explore Dank Jelly Bean&apos;s</h1>
        <div className="bg-black grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 rounded-lg p-4">
          {filteredImages.map((image, index) => (
            <div key={index} className="relative">
              <img
                src={`${process.env.PUBLIC_URL}/images/webp/${image.file_name}`}
                alt={image.file_name}
                className="w-full h-auto rounded-lg"
              />
              <div className="absolute bottom-1 left-1 right-1 bg-gray-800 bg-opacity-75 text-blue-300 text-center py-1 rounded-lg">
                <p>ID: {image.tokenID}</p> {/* Changed to display tokenID */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Explore;
