import React, { useState, useEffect } from 'react';
import ImageCard from './CardFactory';
import images from './Images';

function HomePageGallery() {
    const [selectedImages, setSelectedImages] = useState([]);

    useEffect(() => {
        // Randomly select three images from the manga category
        const jellysImages = getRandomImagesFromCategory(images.jellys, 3);
        setSelectedImages(jellysImages);
        // Now you can use jellysImages elsewhere in your component
        console.log("Selected manga images:", jellysImages);
    }, []);

    return (
        <div className="page-gallery h-5/6 align-middle">
            {selectedImages.map((img, index) => (
                <ImageCard key={index} /* title={img.title} */ imageSrc={img.src} />
            ))}
        </div>
    );
}

// Helper function to select a specified number of random images from a given category
function getRandomImagesFromCategory(category, count) {
    const keys = Object.keys(category);
    const selectedImages = [];
    for (let i = 0; i < count; i++) {
        const randomKey = keys[Math.floor(Math.random() * keys.length)];
        selectedImages.push({ src: category[randomKey], title: getTitleFromKey(randomKey) });
    }
    return selectedImages;
}

// Assuming getTitleFromKey is a function you've defined elsewhere that maps keys to titles.
function getTitleFromKey(key) {
    // This function would implement logic to determine the title based on the key.
    // For simplicity, we're not implementing it here.
    return key; // Placeholder implementation.
}

export default HomePageGallery;
