import React, { useState } from 'react';
import Section from './Section';
import Modal from './Modal'; // Import the Modal component

const SectionThree = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleClick = () => {
        setIsModalOpen(!isModalOpen);
    };

    return (
        <Section
            title="The Dank Jelly Bean's Graphic Novel"
            imgSrc="/images/jellys/sectionThree.png"
            content={
                <>
                    <p>The only way to get the Exclusive Dank Jelly Bean's Graphic Novel is to be holding a Dank Jelly Bean's NFT on PulseChain or the Base Network in March 2025 when it drops.</p>
                    <p>Every holder will receive a unique code to claim the physical copy delivered to their front door.</p>
                    <div className="flex justify-end space-x-4 mt-4">
                        <button 
                            onClick={handleClick} // Update the button to toggle the modal
                            className="bg-blue-500 text-white font-bold py-2 px-4 rounded-lg hover:bg-blue-700"
                        >
                            Mint
                        </button>
                        <a href="https://beatbox.market/contract/0x1db88ddAf7e617de5a030F97a39198693e604504" className="bg-green-500 text-white font-bold py-2 px-4 rounded-lg hover:bg-green-700 flex items-center" target="_blank" rel="noopener noreferrer">
                            <img src="/images/pulsechain.png" alt="Beatbox Market" className="w-4 h-4 mr-2" />
                            Collect
                        </a>
                        <a href="https://magiceden.io/collections/base/0x624172cad7e9032aff6820b9301a5d92200e7658" className="bg-green-500 text-white font-bold py-2 px-4 rounded-lg hover:bg-green-700 flex items-center" target="_blank" rel="noopener noreferrer">
                            <img src="/images/base.png" alt="Magic Eden" className="w-4 h-4 mr-2" />
                            Collect
                        </a>
                    </div>
                    <Modal isOpen={isModalOpen} onClose={handleClick} /> {/* Render the Modal component */}
                </>
            }
        />
    );
};

export default SectionThree;
