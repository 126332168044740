import React from 'react';
import ReactDom from 'react-dom';
import '../App.css';
const MODAL_STYLES = {
  position: 'fixed',
  // justifyContent: 'center',
  // alignItems: 'center',
  // width: '100%',
  // height: 'auto',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // backgroundColor: '#000',
  padding: '50px',
  zIndex: 1001
};


const OVERLAY_STYLES = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, .7)',
  zIndex: 1000
}

const Modal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return ReactDom.createPortal(
    <>
    <div style={OVERLAY_STYLES} />
    <div className="modal" style={MODAL_STYLES} >
      <div className="modal-content">
        <iframe
            src="https://embed.ipfscdn.io/ipfs/bafybeicd3qfzelz4su7ng6n523virdsgobrc5pcbarhwqv3dj3drh645pi/?contract=0x624172Cad7E9032aFF6820b9301A5D92200e7658&chain=%7B%22name%22%3A%22Base%22%2C%22chain%22%3A%22ETH%22%2C%22rpc%22%3A%5B%22https%3A%2F%2F8453.rpc.thirdweb.com%2F%24%7BTHIRDWEB_API_KEY%7D%22%5D%2C%22nativeCurrency%22%3A%7B%22name%22%3A%22Ether%22%2C%22symbol%22%3A%22ETH%22%2C%22decimals%22%3A18%7D%2C%22shortName%22%3A%22base%22%2C%22chainId%22%3A8453%2C%22testnet%22%3Afalse%2C%22slug%22%3A%22base%22%2C%22icon%22%3A%7B%22url%22%3A%22ipfs%3A%2F%2FQmW5Vn15HeRkScMfPcW12ZdZcC2yUASpu6eCsECRdEmjjj%2Fbase-512.png%22%2C%22width%22%3A512%2C%22height%22%3A512%2C%22format%22%3A%22png%22%7D%7D&clientId=5d206b479962cecf89c18967409baeae&theme=dark&primaryColor=blue"
            title="Mint"
            width="600px"
            height="600px"
            style={{ maxWidth: '100%' }}
            frameborder="0"
        ></iframe>
        <button className="close" onClick={onClose}>CLOSE</button>
        {/** this needs styles to place the button bottom center */}
      </div>
    </div>
    </>,
    document.getElementById('portal')
  );
};

export default Modal;
