//Footer.js
import FooterNav from './Nav';
import Social from './Social';

function Footer() {
    return (

        <div className="footer">
          <FooterNav/>
          <Social/>
        </div>
    )   
}

export default Footer;