// Header.js
import logo from '../logo150.png'; // Import the logo image
import Nav from './Nav'

function Header() {

    return (
        <div className="header bg-gray-700 h-35 flex items-center justify-between py-4 px-8">
            <img src={logo} alt="Logo" className="h-20" /> {/* Adjust the height as needed */}
            <Nav/>
        </div>
    );
}

export default Header;
