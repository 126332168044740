import React from 'react';

const Section = ({ title, imgSrc, content }) => {
  return (
    <section className="bg-gray-200 rounded-lg p-6 my-6 mx-auto max-w-6xl">
      <div className="flex flex-col md:flex-row items-center bg-emerald-200 rounded-lg">
        <div className="flex-1 p-6">
          <h2 className="text-3xl text-orange-500">{title}</h2>
          <div className="bg-gray-100 p-6 h-full flex items-center justify-center rounded-lg">
            <p className="text-base">{content}</p>
          </div>
        </div>
        <div className="flex-1 p-6 ">
        <img 
          src={imgSrc} 
          alt={title} 
          className="w-full max-w-full h-auto object-contain rounded-lg"
          style={{ maxHeight: '400px' }} // 0.5rem is equivalent to rounded-lg
        />

        </div>
      </div>
    </section>
  );
};

export default Section;
